/* eslint-disable */
import React from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import '../../styles/components/HomePage.scss';
import * as actions from '../../actions/browseAllActions';
import * as tabActions from '../../actions/tabActions';
import BrowseAllPdfs from '../BrowseAllPdfs/BrowseAllPdfs';
import DownloadAllModal from '../common/DownloadAllModal';
import Header from '../common/Header';
import Preview from '../Preview/Preview';
import RenderingQueue from '../RenderingQueue/RenderingQueue';
import { requestWhiteLabelDoc } from '../../actions/previewActions';
import brandLogo from '../../images/blackrock_advisor_center_logo.svg';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoUrl: _get(window, 'MD.logoUrl', 'https://www.blackrock.com/us/financial-professionals'),
      termsOfUseUrl: _get(
        window,
        'MD.termsOfUseUrl',
        'https://www.blackrock.com/us/financial-professionals/compliance/advisor-terms-of-use'
      ),
      termsOfUseBrandAndShareUrl: _get(
        window,
        'MD.termsOfUseBrandAndShareUrl',
        'https://www.blackrock.com/us/financial-professionals/compliance/advisor-terms-of-use#brandandshare'
      ),
      token: ''
    };
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }
  componentDidMount() {
    const docId = this.searchDocIdInURL();
    if (docId) {
      const previewTabKey = 2;
      this.props.switchTabs(previewTabKey);
      this.props.requestGetReportByID(docId);
      this.props.requestWhiteLabelDoc(docId);
    }
  }

  searchDocIdInURL() {
    const url = new URL(window.location.href);

    let params = null;
    if (url) {
      params = new URLSearchParams(url.search);
    }

    if (params) {
      return params.get('docid') || params.get('docId');
    }

    return null;

    if (queryParam) {
      return currentQuery[queryParam];
    } else {
      return this.getQueryVariable('docid');
    }
  }

  handleTabSelect(tabKey) {
    const browseAllTabKey = 1;
    const previewTabKey = 2;
    if (tabKey == this.state.activeTab) {
      return;
    }
    if (tabKey === browseAllTabKey) {
      this.props.requestGetRecentReports();
    }
    if (
      this.props.previewDocId == '' &&
      this.props.renderingQueueReports &&
      this.props.renderingQueueReports.length
    ) {
      const currentDoc = this.props.renderingQueueReports[0];
      this.props.requestWhiteLabelDoc(currentDoc.documentId);
      const tabParams = {
        previewDocId: currentDoc.documentId,
        activeTab: previewTabKey,
        previewHeadline: currentDoc.headline,
        previewSubject: currentDoc.subject
      };
      this.props.openPreviewTab(tabParams);
    }
    this.props.switchTabs(tabKey);
  }

  render() {
    return (
      <div className="pdftool-homepage">
        <div className="brand-logo">
          <a href={this.state.logoUrl}>
            <img src={brandLogo} alt="BlackRock for Advisors homepage" />
          </a>
        </div>
        <DownloadAllModal />
        <Header />
        <Row className="container main-content">
          <Col md={3} sm={4} className="left-content">
            <Scrollbars autoHeight autoHeightMin={400} autoHeightMax={4000}>
              <RenderingQueue />
            </Scrollbars>
          </Col>
          <Col md={9} sm={8} className="right-content">
            <Tabs
              id={'home-tabs'}
              onSelect={this.handleTabSelect}
              activeKey={this.props.activeTab}
              className="right-content-content"
            >
              <Tab eventKey={1} title="Browse all Content">
                <BrowseAllPdfs />
              </Tab>
              <Tab eventKey={2} title="Preview">
                <Preview key={this.props.whiteLabelledPdf} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <footer className="disclosures">
          <p>
            As a reminder, You, as a user of this tool, have agreed to and are responsible for
            ensuring you are sharing the latest content with your clients. Please check back
            regularly to ensure content remains available and up to date. The Terms of Use in effect
            for this and other Advisor Center tools are available{' '}
            <a href={this.state.termsOfUseBrandAndShareUrl}>here</a>.
          </p>
          <p>
            The information included in this material has been taken from trade and other sources
            considered to be reliable. We do not represent that this information is accurate and
            complete, and it should not be relied upon as such. Any opinions expressed in this
            material reflect our analysis at this date and are subject to change. The information
            and opinions contained in this material are derived from proprietary and nonproprietary
            sources deemed by BlackRock to be reliable, but are not guaranteed as to accuracy.
          </p>
          <p>
            This information should not be relied upon as research, investment advice, or a
            recommendation regarding any products, strategies, or any security in particular. This
            material is strictly for illustrative, educational, or informational purposes and is
            subject to change.
          </p>
          <p>
            Please note the materials included may not be approved by your firm. Please consult with
            your firm's compliance department to ensure appropriate approvals are in place before
            sharing.
          </p>
          <p>
            Prepared by BlackRock Investments, LLC, member FINRA. BlackRock Fund Advisors, an
            affiliate of BlackRock Investments, LLC, is a registered investment adviser.
          </p>
          <p>
            The tools are provided on an "as-is" basis. BlackRock expressly disclaims all
            warranties, express or implied, statutory or otherwise with respect to the tools (and
            any results obtained from their use) including, without limitation, all warranties or
            merchantability, fitness for a particular purpose or use, accuracy, completeness,
            originality and/or non-infringement. In no event shall BlackRock have any liability for
            any claims, damages, obligations, liabilities or losses relating to the tools including,
            without limitation, any liability for any direct, indirect, special, incidental,
            punitive and/or consequential damages (including loss of profits or principal). The use
            of the tool is subject to the BlackRock{' '}
            <a href={this.state.termsOfUseUrl}>Terms of Use</a>.
          </p>
          <p>
            &copy; 2023 BlackRock, Inc. or its affiliates. All rights reserved. <b>ALADDIN</b> and{' '}
            <b>BLACKROCK</b> are trademarks of BlackRock, Inc., or its affiliates. All other
            trademarks are those of their respective owners.
          </p>
          <p>USRRMH0923U/S-3099447</p>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.browseAllReducer.reports,
    recentReports: state.browseAllReducer.recentReports,
    activeTab: state.tabsReducer.activeTab,
    previewDocId: state.tabsReducer.previewDocId,
    whiteLabelledPdf: state.previewReducer.whiteLabelledPdf,
    renderingQueueReports: state.renderingQueueReducer.renderingQueueReports
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestGetReportByID: (docId) => {
      return dispatch(tabActions.requestGetReportByID(docId));
    },
    requestGetRecentReports: () => {
      return dispatch(actions.requestGetRecentReports());
    },
    switchTabs: (tabKey) => {
      return dispatch(tabActions.switchTabs(tabKey));
    },
    requestWhiteLabelDoc: (docId) => {
      return dispatch(requestWhiteLabelDoc(docId));
    },
    openPreviewTab: (inputParams) => {
      return dispatch(tabActions.openPreviewTab(inputParams));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
